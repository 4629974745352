
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class EditStat extends Vue {
  private dialog = false;
  private tempChart = 'line';
  private chart = 'line';

  public show() {
    this.tempChart = this.chart;
    this.dialog = true;
  }

  private async apply() {
    this.chart = this.tempChart;
    this.$emit('change', this.chart == 'stacked' ? 'total' : '');
    this.dialog = false;
  }
}
