
import Vue from 'vue';
import Component from 'vue-class-component';
import VChart from 'vue-echarts';
import { dispatchGetScanStats } from '@/store/scans/actions';
import { readFilters, readScanStats } from '@/store/scans/getters';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {
    VChart,
  },
})
export default class Stats extends Vue {
  @Prop() private chartType;
  private options = {
    xAxis: {
      data: [''],
    },
    yAxis: {},
    legend: {
      bottom: 0,
    },
    series: [
      {
        name: 'Authentic',
        type: 'bar',
        stack: '',
        label: {
          show: true,
          position: 'inside',
        },
        data: [0],
        color: '#00A300',
      },
      {
        name: 'Suspect',
        type: 'bar',
        stack: '',
        label: {
          show: true,
          position: 'inside',
        },
        data: [0],
        color: '#D10000',
      },
    ],
    animation: false,
  };

  private async mounted() {
    this.update();
  }

  private get filters() {
    return readFilters(this.$store);
  }

  private get stats() {
    return readScanStats(this.$store);
  }

  @Watch('filters')
  private async update() {
    await dispatchGetScanStats(this.$store, {
      appId: Number(this.$router.currentRoute.params.id),
      filters: this.filters,
    });
    this.redraw();
  }

  @Watch('chartType')
  private redraw() {
    this.options.xAxis.data = this.stats.map((x) => x.month);
    this.options.series[0].data = this.stats.map((x) => x.authentic);
    this.options.series[1].data = this.stats.map((x) => x.suspect);
    this.options.series[0].stack = this.chartType;
    this.options.series[1].stack = this.chartType;
  }
}
