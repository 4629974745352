var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('gmap-map',{ref:"gmap",staticStyle:{"width":"100%","height":"100%"},attrs:{"id":"gmap","center":{ lat: 0.0, lng: 0.0 },"zoom":2,"options":{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUi: false,
    }}},[_c('gmap-cluster',_vm._l((_vm.locations),function(item,index){return _c('gmap-marker',{key:index,attrs:{"position":item.position,"icon":item.icon}})}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }