
import { Component, Vue, Watch } from 'vue-property-decorator';
import { GmapMap } from 'vue2-google-maps';
import { IScan } from '@/interfaces';
import { readScans } from '@/store/scans/getters';
import html2canvas from 'html2canvas';

interface ILocation {
  position: object;
  icon: object;
}

@Component
export default class GoogleMap extends Vue {
  private locations: ILocation[] = [];

  private get scans() {
    return readScans(this.$store);
  }

  public changeCenter(position) {
    const map = (this.$refs.gmap as GmapMap).$mapObject;
    map.setCenter(position);
    map.setZoom(16);
  }

  private mounted() {
    this.updateLocations();
    this.$root.$on('exportMap', (filename) => this.download(filename));
  }

  @Watch('scans')
  private onPropertyChanged() {
    this.updateLocations();
  }

  private updateLocations() {
    this.locations = [];
    this.scans
      .filter((el) => el.geo_lat !== 0.0 && el.geo_lng !== 0.0)
      .forEach((el) => {
        this.locations.push({
          position: { lat: el.geo_lat, lng: el.geo_lng },
          icon: {
            url: this.getPlaceIcon(el),
            scaledSize: { width: 32, height: 50 },
          },
        });
      });
  }

  private getPlaceIcon(scan: IScan) {
    const name = scan.result === 0 ? 'suspect' : scan.image;
    try {
      return require(`@/assets/products/places/${name}.png`);
    } catch (e) {
      return require('@/assets/products/places/protect.png');
    }
  }

  private download(filename) {
    html2canvas(document.getElementById('gmap')!, {
      useCORS: true,
    }).then(function (canvas) {
      const data = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = data;
      link.download = filename + '.png';
      link.click();
    });
  }
}
