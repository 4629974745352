
import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment';
import { readProducts } from '@/store/products/getters';
import { dispatchSetFilters } from '@/store/scans/actions';
import { Prop } from 'vue-property-decorator';
import { IFilterItem, IScan } from '@/interfaces';
import {
  dispatchClearFilters,
  dispatchRemoveFilter,
} from '@/store/scans/actions';
import { readFilters, readVersions } from '@/store/scans/getters';

@Component
export default class Filters extends Vue {
  private dateSuggestions = [
    { id: 'last_day', text: 'Last Day' },
    { id: 'last_3_months', text: 'Last 3 Months' },
    { id: 'last_year', text: 'Last Year' },
  ];

  private sortBy = [
    'start_date',
    'end_date',
    'product',
    'result',
    'app_version',
  ];

  private results: IFilterItem[] = [
    { type: 'result', name: 'Authentic', value: 1 },
    { type: 'result', name: 'Suspect', value: 0 },
  ];

  private dialog = false;
  private startDate = '';
  private endDate = '';
  private menu1 = false;
  private menu2 = false;
  private tempFilters: IFilterItem[] = [];

  private get products() {
    return readProducts(this.$store);
  }

  private get versions() {
    return readVersions(this.$store);
  }

  private get filters() {
    return readFilters(this.$store);
  }

  private get productList() {
    const list: IFilterItem[] = [];
    this.products.forEach((el) => {
      list.push({
        type: 'product',
        name: el.name,
        value: el.id,
      });
    });
    return list;
  }

  private get versionList() {
    const list: IFilterItem[] = [];
    this.versions.forEach((el) => {
      list.push({
        type: 'app_version',
        name: el.app_version,
        value: el.app_version,
      });
    });
    return list;
  }

  private async change() {
    this.tempFilters = this.filters;

    const { startDate, endDate } = Filters.getDateFilters(this.filters);
    this.startDate = startDate;
    this.endDate = endDate;

    this.dialog = true;
  }

  private remove(filter: IFilterItem) {
    dispatchRemoveFilter(this.$store, { data: filter });
  }

  private clear() {
    dispatchClearFilters(this.$store);
  }

  private apply() {
    this.tempFilters = this.addDateFilters(this.tempFilters);
    this.tempFilters = this.customSort({
      data: this.tempFilters,
      sortBy: this.sortBy,
      sortField: 'type',
    });
    dispatchSetFilters(this.$store, { data: this.tempFilters });
    this.dialog = false;
  }

  private getProductImage(index: number) {
    try {
      return require(`@/assets/products/${this.products[index].image}.png`);
    } catch (e) {
      return require('@/assets/products/protect.png');
    }
  }

  private static getDateFilters(filters: IFilterItem[]) {
    let index = filters.findIndex((el) => el.type === 'start_date');
    const startDate = index >= 0 ? filters[index].value : '';

    index = filters.findIndex((el) => el.type === 'end_date');
    const endDate = index >= 0 ? filters[index].value : '';

    return { startDate, endDate };
  }

  private addDateFilters(filters: IFilterItem[]) {
    // Handle start date
    let index = filters.findIndex((el) => el.type === 'start_date');
    if (this.startDate !== null && this.startDate !== '') {
      const date = {
        type: 'start_date',
        name: 'Start: ' + this.startDate,
        value: this.startDate,
      };
      if (index >= 0) {
        filters.splice(index, 1, date);
      } else {
        filters.push(date);
      }
    } else {
      if (index >= 0) {
        filters.splice(index, 1);
      }
    }

    // Handle end date
    index = filters.findIndex((el) => el.type === 'end_date');
    if (this.endDate !== null && this.endDate !== '') {
      const date = {
        type: 'end_date',
        name: 'End: ' + this.endDate,
        value: this.endDate,
      };
      if (index >= 0) {
        filters.splice(index, 1, date);
      } else {
        filters.push(date);
      }
    } else {
      if (index >= 0) {
        filters.splice(index, 1);
      }
    }

    return filters;
  }

  private customSort({ data, sortBy, sortField }) {
    const sortByObject = sortBy.reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      };
    }, {});
    return data.sort(
      (a, b) => sortByObject[a[sortField]] - sortByObject[b[sortField]],
    );
  }

  private getRange(action: String) {
    switch (action) {
      case 'last_day':
        this.startDate = moment().format('YYYY-MM-DD');
        this.endDate = moment().format('YYYY-MM-DD');
        break;
      case 'last_3_months':
        this.startDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
        this.endDate = moment().format('YYYY-MM-DD');
        break;
      case 'last_year':
        this.startDate = moment().subtract(1, 'years').format('YYYY-MM-DD');
        this.endDate = moment().format('YYYY-MM-DD');
        break;
      default:
        break;
    }
  }
}
