
import moment from 'moment';
import Vue from 'vue';
import Component from 'vue-class-component';
import { api } from '@/api';
import { mainModule } from '@/store/main';
import { readFilters } from '@/store/scans/getters';

@Component
export default class ExportScanStats extends Vue {
  private dialog = false;
  private filename = '';
  private isFilters = false;
  private valid = false;
  private rules = {
    required: (value) => value.trim().length > 0 || 'Required',
  };

  private get filters() {
    return readFilters(this.$store);
  }

  private get defaultFilename() {
    return 'scan_stats_' + moment().format('YYYYMMDD-HHmm');
  }

  public show() {
    this.dialog = true;
    this.filename = this.defaultFilename;
  }

  private async doExport() {
    const file = await api.exportScanStats(
      mainModule.state.token,
      Number(this.$router.currentRoute.params.id),
      this.isFilters ? this.filters : [],
    );
    this.download('data:text/csv;charset=utf-8,' + file.data);
    this.dialog = false;
  }

  private download(content) {
    const data = encodeURI(content);
    const link = document.createElement('a');
    link.href = data;
    link.download = this.filename + '.csv';
    link.click();
  }
}
