
import moment from 'moment';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class ExportMap extends Vue {
  private dialog = false;
  private filename = '';
  private valid = false;
  private rules = {
    required: (value) => value.trim().length > 0 || 'Required',
  };

  private get defaultFilename() {
    return 'scan_map_' + moment().format('YYYYMMDD-HHmm');
  }

  public show() {
    this.dialog = true;
    this.filename = this.defaultFilename;
  }

  private async doExport() {
    this.$root.$emit('exportMap', this.filename);
    this.dialog = false;
  }
}
