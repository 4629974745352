
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readFilters, readScans } from '@/store/scans/getters';
import {
  dispatchClearFilters,
  dispatchClearScans,
  dispatchGetScans,
  dispatchGetVersions,
} from '@/store/scans/actions';
import { IFilterItem, IScan } from '@/interfaces';
import EditChart from './EditChart.vue';
import ExportScans from './ExportScans.vue';
import ExportMap from './ExportMap.vue';
import ExportScanStats from './ExportScanStats.vue';
import Filters from './Filters.vue';
import ScanStats from './ScanStats.vue';
import { dispatchGetProducts } from '@/store/products/actions';
import GoogleMap from './GoogleMap.vue';
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

const PAGE_SIZE = 100;
const STATUS_ICONS = ['suspect.png', 'authentic.png'];

@Component({
  components: {
    EditChart,
    ExportScans,
    ExportMap,
    ExportScanStats,
    Filters,
    ScanStats,
    GoogleMap,
    Splitpanes,
    Pane,
  },
})
export default class Scans extends Vue {
  private headers = [
    {
      text: 'Timestamp',
      sortable: true,
      value: 'timestamp',
      align: 'center',
    },
    {
      text: 'Consumer',
      sortable: true,
      value: 'consumer',
      align: 'center',
    },
    {
      text: 'Product',
      sortable: true,
      value: 'product',
      align: 'left',
    },
    {
      text: 'Information',
      sortable: true,
      value: 'information',
      align: 'left',
    },
    {
      text: 'Scan Result',
      sortable: true,
      value: 'result',
      align: 'center',
    },
    {
      text: 'Address',
      sortable: true,
      value: 'address',
      align: 'left',
    },
    {
      text: 'App Version',
      sortable: true,
      value: 'app_version',
      align: 'center',
    },
  ];

  private appId = 0;
  private tableHeight = 0;
  private entries = true;
  private map = false;
  private stats = false;
  private sortBy = this.headers[0].value;
  private sortDesc = true;
  private scrollPosition = 0;
  private chartType = '';
  private busy = false;

  private beforeMount() {
    this.updateList();
  }

  private mounted() {
    const el = document.getElementById('dtable');
    el?.addEventListener('wheel', this.handleScroll);
  }

  private beforeRouteUpdate(to, from, next) {
    next();
    this.updateList();
  }

  private clear() {
    this.sortBy = this.headers[0].value;
    this.sortDesc = true;
    this.scrollPosition = 0;
    this.busy = false;
    dispatchClearScans(this.$store);
    dispatchClearFilters(this.$store);
  }

  private get scans() {
    return readScans(this.$store);
  }

  private get filters() {
    return readFilters(this.$store);
  }

  private getProductImage(image: string) {
    try {
      return require(`@/assets/products/${image}.png`);
    } catch (e) {
      return require('@/assets/products/protect.png');
    }
  }

  private getResultIcons(result: number) {
    return require('@/assets/' + STATUS_ICONS[result]);
  }

  private async updateList() {
    this.appId = Number(this.$router.currentRoute.params.id);
    this.clear();
    await dispatchGetProducts(this.$store, this.appId);
    await dispatchGetVersions(this.$store, this.appId);
    this.getLists();
  }

  @Watch('filters')
  private async getLists() {
    await dispatchGetScans(this.$store, {
      appId: this.appId,
      filters: this.filters,
      skip: 0,
      limit: PAGE_SIZE,
      sort: this.sortBy,
      desc: this.sortDesc,
    });
  }

  private handleResize() {
    this.tableHeight =
      window.innerHeight -
      /* header */ 64 -
      /* title */ 64 -
      /* tabs */ 48 -
      /* buttons */ 88 -
      /* filters */ 60 -
      /* footer*/ 48;
  }

  private async handleScroll() {
    const table = document.querySelector('div.v-data-table__wrapper')!;
    const pos = table.clientHeight + table.scrollTop;
    if (
      !this.busy &&
      pos !== this.scrollPosition &&
      pos >= table.scrollHeight - 50
    ) {
      this.busy = true;
      await dispatchGetScans(this.$store, {
        appId: this.appId,
        filters: this.filters,
        skip: this.scans.length,
        limit: PAGE_SIZE,
        sort: this.sortBy,
        desc: this.sortDesc,
      });
      this.busy = false;
    }
    this.scrollPosition = pos;
  }

  private showEdit() {
    (this.$refs.editChart as EditChart).show();
  }

  private showExport() {
    if (this.entries) (this.$refs.exportScansDialog as ExportScans).show();
    else if (this.map) (this.$refs.exportMapDialog as ExportMap).show();
    else (this.$refs.exportStatsDialog as ExportScanStats).show();
  }

  private handleOptionsUpdate({ sortBy, sortDesc }) {
    this.sortBy = sortBy;
    this.sortDesc = sortDesc;
    this.getLists();
  }

  private get computedHeaders() {
    return this.map
      ? this.headers.filter((el) => el.value !== 'address')
      : this.headers;
  }

  private changeMapCenter(item) {
    if (this.map) {
      const position = { lat: item.geo_lat, lng: item.geo_lng };
      (this.$refs.gmap as GoogleMap).changeCenter(position);
    }
  }

  private changeChart(type) {
    this.chartType = type;
  }
}
